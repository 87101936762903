<template>
  <div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-center" style="min-width:125px">
            Iteration
          </th>
          <th v-for="(value, name) in data[0]" :key="name">
            {{ name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td class="text-center">
            {{ index + 1 }}
          </td>
          <td v-for="(value, name) in item" :key="name">
            {{ value.toExponential(3) }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'SolverBalanceTable',
  components: {},
  props: {
    data: { type: Array, required: true },
  },
  data() {
    return {
      tableHeaders: [''],
    };
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style scoped></style>
