<template>
  <div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-center" style="width:125px">
            Global Iteration
          </th>
          <th class="text-center" style="width:150px">
            Chamber Iteration
          </th>
          <th class="text-center" style="width:250px">
            Setpoint Value
          </th>
          <th class="text-center" style="width:250px">
            Flue Gas Temperature
          </th>
          <th class="text-center" style="width:250px">
            Temperature Iterations
          </th>
          <!-- <th>Species</th> -->
          <th
            class="text-center"
            style="width:200px"
            v-for="(value, name) in data[0].equicalc.equilibriumStats
              .species"
            :key="name"
          >
            {{ name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in data" :key="index">
          <td class="text-center">{{ row.globalIteration }}</td>
          <td class="text-center">{{ row.localIteration }}</td>
          <td class="text-center">
            {{ row.setpoint.toExponential(4) }}
          </td>
          <td class="text-center">
            {{ round(row.equicalc.T - 273.15, 3) }}
          </td>
          <td class="text-center">
            {{ row.equicalc.temperatureIterations }}
          </td>
          <td
            class="text-center"
            v-for="(value, name) in row.equicalc.equilibriumStats
              .species"
            :key="name"
          >
            {{ value.toExponential(4) }}
          </td>
        </tr>
        <!-- <tr v-for="(subitem, subindex) in data" :key="subindex">
            <td class="text-center">
              {{ index + 1 }}
            </td>
            <td v-for="(value, name) in item" :key="name">
              {{ value.toExponential(3) }}
            </td>
          </tr> -->
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'SolverChamberBalanceTable',
  components: {},
  props: {
    data: { type: Array, required: true },
  },
  data() {
    return {
      tableHeaders: [''],
    };
  },
  methods: {
    round(value, precision) {
      return _.round(value, precision);
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style scoped></style>
