<template>
  <v-container fluid>
    <v-overlay v-show="calculationRunning" opacity="0.7">
      <v-container fluid>
        <v-row justify="center">
          <v-progress-circular indeterminate color="info">
          </v-progress-circular>
        </v-row>
        <v-row justify="center">
          <p class="my-4">Solving, please wait...</p>
        </v-row>
        <v-row justify="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="cancelSolver"
                :disabled="!calculationRunning"
                v-show="calculationRunning"
                fab
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span> Abort </span>
          </v-tooltip>
        </v-row>
      </v-container>
    </v-overlay>
    <v-expansion-panels class="my-4" v-model="panels" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Controls</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-btn
                  @click="solveProject"
                  :disabled="calculationRunning"
                >
                  Solve
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col align="right">
                <router-link
                  to="/docs/solver"
                  v-slot="{ href, navigate }"
                >
                  <v-btn :href="href" @click="navigate">
                    Documentation
                  </v-btn>
                </router-link>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Solver Settings</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-expansion-panels class="my-4" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h2>Global Solver</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" sm="6" md="4" lg="2">
                    <v-numeric-text-field-unit
                      v-model="
                        globalSolverSettings.numberOfIterations
                      "
                      :settings="settings.integer"
                      label="Maximum number of iterations"
                      :integer="true"
                    >
                    </v-numeric-text-field-unit>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="2">
                    <v-numeric-text-field-unit
                      v-model="globalSolverSettings.convergence"
                      :settings="settings.float"
                      label="Convergence Criteria"
                    ></v-numeric-text-field-unit>
                  </v-col>
                </v-row>
                <v-btn @click="resetGlobalSolverSettings">
                  Reset to default
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h2>Combustion Chambers</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="chamber in combustionChambers"
                    :key="chamber.publicId"
                  >
                    <v-expansion-panel-header>
                      <h2>
                        {{ chamber.name }}
                      </h2>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card
                        class="my-2"
                        v-show="
                          chamber.setpointActive ||
                            chamber.autoDimension
                        "
                      >
                        <v-card-title>
                          Setpoint Solver
                        </v-card-title>
                        <v-card-text>
                          <v-numeric-text-field-unit
                            v-model="
                              chamber.solverSettings.setpoint
                                .numberOfIterations
                            "
                            :settings="settings.integer"
                            label="Maximum number of iterations"
                            :integer="true"
                          >
                          </v-numeric-text-field-unit>
                          <v-numeric-text-field-unit
                            v-model="
                              chamber.solverSettings.setpoint
                                .convergence
                            "
                            :settings="settings.float"
                            label="Convergence Criteria"
                          ></v-numeric-text-field-unit>
                          <v-btn
                            @click="
                              resetChamberSetpointSolverSettings(
                                chamber,
                              )
                            "
                          >
                            Reset to default
                          </v-btn>
                        </v-card-text>
                      </v-card>
                      <v-card class="my-2">
                        <v-card-title>
                          Equilibrium Solver
                        </v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="12" lg="6">
                              <v-card>
                                <v-card-title>
                                  Temperature
                                </v-card-title>
                                <v-card-text>
                                  <v-numeric-text-field-unit
                                    v-model="
                                      chamber.solverSettings
                                        .equiCalcTemperature
                                        .numberOfIterations
                                    "
                                    :settings="settings.integer"
                                    label="Maximum number of iterations"
                                    :integer="true"
                                  >
                                  </v-numeric-text-field-unit>
                                  <v-numeric-text-field-unit
                                    v-model="
                                      chamber.solverSettings
                                        .equiCalcTemperature
                                        .convergence
                                    "
                                    :settings="settings.float"
                                    label="Accuracy"
                                  ></v-numeric-text-field-unit>
                                  <v-numeric-text-field-unit
                                    v-model="
                                      chamber.solverSettings
                                        .equiCalcTemperature.alpha
                                    "
                                    :settings="settings.float"
                                    label="Relaxation"
                                  ></v-numeric-text-field-unit>
                                  <v-btn
                                    @click="
                                      resetChamberEquiCalcTemperatureSolverSettings(
                                        chamber,
                                      )
                                    "
                                  >
                                    Reset to default
                                  </v-btn>
                                </v-card-text>
                              </v-card>
                            </v-col>
                            <v-col cols="12" md="12" lg="6">
                              <v-card>
                                <v-card-title>
                                  Composition
                                </v-card-title>
                                <v-card-text>
                                  <v-numeric-text-field-unit
                                    v-model="
                                      chamber.solverSettings
                                        .equiCalcSpecies
                                        .numberOfIterations
                                    "
                                    :settings="settings.integer"
                                    label="Maximum number of iterations"
                                    :integer="true"
                                  >
                                  </v-numeric-text-field-unit>
                                  <v-numeric-text-field-unit
                                    v-model="
                                      chamber.solverSettings
                                        .equiCalcSpecies.convergence
                                    "
                                    :settings="settings.integer"
                                    label="Accuracy"
                                  ></v-numeric-text-field-unit>
                                  <v-btn
                                    @click="
                                      resetChamberEquiCalcSpeciesSolverSettings(
                                        chamber,
                                      )
                                    "
                                  >
                                    Reset to default
                                  </v-btn>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-btn
                        @click="resetChamberSolverSettings(chamber)"
                      >
                        Reset to default
                      </v-btn>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-btn @click="resetSolverSettings()">
            Reset to default
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Solver Stats</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-expansion-panels v-model="statsPanels" multiple>
            <!-- <v-expansion-panel>
              <v-expansion-panel-header>
                <h2>Convergence Status</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                  <li
                    :style="
                      convergedStyle(
                        globalSolverStats.elementalBalanceConverged,
                      )
                    "
                  >
                    Global Element Balance
                  </li>
                  <li
                    :style="
                      convergedStyle(combustionChambersConverged)
                    "
                  >
                    Combustion Chambers:
                  </li>
                  <ul>
                    <li
                      v-for="chamber in combustionChambers"
                      :key="chamber.publicId"
                      :style="
                        convergedStyle(
                          chamber.solverStats.isConverged,
                        )
                      "
                    >
                      {{ chamber.name }}
                    </li>
                  </ul>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel> -->
            <v-expansion-panel>
              <v-expansion-panel-header
                :style="convergedStyle(globalSolverStats.isConverged)"
              >
                <h2>Global Stats</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <h3>Element Inflows [kg/s]</h3>
                <SolverBalanceTable
                  :data="globalSolverStats.log.in"
                />
                <h3>Element Outflows [kg/s]</h3>
                <SolverBalanceTable
                  :data="globalSolverStats.log.out"
                />
                <h3>Element Absolute Imbalance [kg/s]</h3>
                <SolverBalanceTable
                  :data="globalSolverStats.log.delta"
                />
                <h3>Element Relative Imbalance [-]</h3>
                <SolverBalanceTable
                  :data="globalSolverStats.log.rel"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                :style="convergedStyle(combustionChambersConverged)"
              >
                <h2>Combustion Chambers</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="chamber in combustionChambers"
                    :key="chamber.publicId"
                  >
                    <v-expansion-panel-header
                      :style="
                        convergedStyle(
                          chamber.solverStats.isConverged,
                        )
                      "
                    >
                      {{ chamber.name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <SolverChamberBalanceTable
                        :data="chamber.solverStats.log"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-show="info.length > 0">
        <v-expansion-panel-header>
          <h2>Error Details</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ul>
            <li v-for="(item, key) in info" :key="key">
              {{ item }}
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-show="traceback !== ''">
        <v-expansion-panel-header>
          <h2>Traceback</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-textarea
            :readonly="true"
            v-model="traceback"
          ></v-textarea>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import UnitConverter from '@/plugins/unitconverter';

import SolverBalanceTable from '@/components/results/SolverBalanceTable';
import SolverChamberBalanceTable from '@/components/results/SolverChamberBalanceTable';

export default {
  components: { SolverBalanceTable, SolverChamberBalanceTable },
  data() {
    return {
      panels: [0, 1],
      statsPanels: [1],
      settings: {
        integer: {
          label: null,
          type: UnitConverter.types.UNITLESS,
          min: 0,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 0,
          units: [''],
          precisions: [0],
        },
        float: {
          label: null,
          type: UnitConverter.types.UNITLESS,
          min: 0,
          max: null,
          baseUnit: '',
          unit: '',
          precision: 10,
          units: [''],
          precisions: [10],
        },
      },
      traceback: '',
      info: [],
      stats: {
        global: { in: [], out: [], delta: [], rel: [] },
        chambers: [],
      },
      source: null,
    };
  },
  methods: {
    solveProject() {
      // this.$store.dispatch('misc/setLoading', {
      //   status: true,
      //   text: 'Solving, please wait...',
      // });
      this.$store
        .dispatch('projects/solveProject', {
          project: this.$store.getters['projects/currentProject'],
        })
        .then(response => {
          // this.$store.dispatch('misc/setLoading', false);
          console.log(response.data);
          this.traceback = response.data.traceback;
          this.info = [];
          this.stats = response.data.project.solverStats;
          // console.log(this.stats);
          var snack = {
            text: response.data.msg,
            color: this.stats.isConverged ? 'success' : 'warning',
            timeout: null,
          };
          this.$store.dispatch('misc/setSnack', snack);
        })
        .catch(error => {
          this.$store.dispatch('misc/setLoading', false);
          console.log(error);
          var message = axios.isCancel(error)
            ? error.message
            : error.response.data.msg;
          var snack = {
            text: message,
            color: 'error',
            timeout: null,
          };
          this.info = error.response.data.info;
          // this.traceback = error.response.data.traceback;
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    cancelSolver() {
      this.$store.dispatch('projects/cancelRequest', {
        message: 'Calculation canceled by user!',
      });
    },
    resetSolverSettings() {
      this.resetGlobalSolverSettings();
      this.combustionChambers.forEach(chamber =>
        this.resetChamberSolverSettings(chamber),
      );
    },
    resetGlobalSolverSettings() {
      var project = this.$store.getters['projects/currentProject'];
      project.solverSettings = this.$store.getters[
        'projects/emptySolverSettings'
      ]();
    },
    resetChamberSolverSettings(chamber) {
      chamber.solverSettings = this.$store.getters[
        'combustionChambers/emptySolverSettings'
      ]();
    },
    resetChamberSetpointSolverSettings(chamber) {
      chamber.solverSettings.setpoint = this.$store.getters[
        'combustionChambers/emptySolverSettings'
      ]().setpoint;
    },
    resetChamberEquiCalcTemperatureSolverSettings(chamber) {
      chamber.solverSettings.equiCalcTemperature = this.$store.getters[
        'combustionChambers/emptySolverSettings'
      ]().equiCalcTemperature;
    },
    resetChamberEquiCalcSpeciesSolverSettings(chamber) {
      chamber.solverSettings.equiCalcSpecies = this.$store.getters[
        'combustionChambers/emptySolverSettings'
      ]().equiCalcSpecies;
    },
    convergedStyle(isConverged) {
      var color = isConverged ? 'green' : 'red';
      return 'color:' + color;
    },
  },
  computed: {
    ...mapGetters('combustionChambers', ['combustionChambers']),
    ...mapGetters('projects', {
      globalSolverSettings: 'solverSettings',
      globalSolverStats: 'solverStats',
      calculationRunning: 'calculationRunning',
    }),
    combustionChambersConverged() {
      return !this.combustionChambers.some(
        chamber => !chamber.solverStats.isConverged,
      );
    },
    // calculationRunning() {
    //   return this.source != null;
    // },
  },
  mounted() {},
};
</script>

<style></style>
